/* stylelint-disable scss/selector-no-redundant-nesting-selector */
/* stylelint-disable selector-class-pattern */
/*
 * File: survey-container.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:47:29 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 25th August 2021 2:05:01 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../variables';
/*
  Save, Draft and Clear button at the top of the survey
 */
.survey-control-btns {
  display: flex;
  justify-content: flex-start;
  padding: 0 12vw 10px;
  width: 100%;

  .control-btn-container {
    display: flex;
    width: fit-content;
    // background-color: #932b8e;

    & > button.btn,
    & > .dropdown {
      @include box-shadow($shadow-box);

      margin: 0 4px !important;
    }
  }

  .draft-drop-down-text {
    display: inline-flex;
    justify-content: space-between;
    min-width: 300px;
    padding: 3px 0;
  }

  .dropdown.btn-group {
    display: flex;
    max-width: fit-content;
  }
}

/* The survey itself */
.survey-container {
  background-color: #fff;
  border-radius: 0.25em;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  // justify-self: center;
  margin: 0 auto;
  max-width: 10in;
  padding: 15px;

  // Bottom of the survey
  .panel-footer.card-footer {
    background-color: inherit;
    display: flex;
    width: 100%;

    .sv-footer {
      &__left {
        padding: auto;
      }

      &__right {
        display: flex;
        // float: right;
        justify-content: flex-end;
        width: 100%;
      }
    }
  }

  .survey-btn {
    @include border-radius(5px);

    background-color: rgba(var(--secondary-color), 1);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    color: rgba(var(--primary-color-text), 1);
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    margin-left: 10px;
    min-width: 100px !important;
    padding: 0.375rem 2em;
    text-align: center;
  }
}

// The restyling of the survey created by SurveyJS
.sv_main {
  background-color: #fff;
  font-family: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;

  .sv_custom_header {
    height: auto;
  }

  .sv_container {
    color: rgba(var(--secondary-color-text), 1);

    .sv_row {
      .btn-group {
        margin-top: 16px;
      }

      //AIGS questions
      h5 {
        font-size: 17px;
      }

      //AIGS question numbers
      span.sv_q_num {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .panel-heading.card-header {
      background-color: #fff;

      .sv_header__text {
        h3 {
          font-size: 32px;
        }

        h3,
        h5 {
          font-weight: 300;
        }
      }
    }

    .panel-body.card-block {
      padding: 15px;

      h4 {
        font-size: 26px;
        font-weight: 300;
        margin-bottom: 25px;
      }

      .progress.center-block {
        box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
        width: 80% !important;

        .progress-bar {
          background-color: rgba(var(--secondary-color), 1);
          border-radius: 0.25rem;
          margin-left: 0 !important;
          margin-right: 0 !important;
        }
      }

      .btn-secondary:not(:disabled):not(.disabled).active {
        background-color: #20a8d8;
      }
    }
  }

  .card-footer {
    background-color: inherit !important;
    justify-content: flex-end;
    padding: 0.75rem 1.25rem;

    .sv-footer {
      &__right {
        justify-self: flex-end;
      }
    }
  }
}
