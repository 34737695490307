/*
 * File: scorecard-scores-table.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:42:02 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.scorecard-scores-table {
  @include box-shadow($shadow-box);
  @include border-radius(5px);

  background-color: #fff !important;

  .table-responsive {
    height: fit-content !important;
    overflow-y: hidden;
  }

  table {
    @include border-radius(5px);

    thead {
      // background-color: #cdcdcd;
      @include border-radius(5px 5px 0 0);

      tr:first-child {
        th:first-child {
          @include border-radius(5px 0 0 0);

          /* your stuff here */
        }

        th:last-child {
          @include border-radius(0 5px 0 0);
        }
      }
    }

    .score-table-details-section {
      background-color: #ebedef;
      padding: 16px;
      width: 100%;

      .requirement-details {
        border-right: 1px solid #a9a9a9;
        display: flex;
        flex-direction: row;
        justify-content: space-around !important;
        max-width: 250px;

        &:last-child {
          border-right: 0;
        }

        p.req-name-weight,
        .req-score {
          padding: 0 8px;
        }

        p.req-name-weight {
          span {
            // font-size: 18px;
            font-weight: bolder;
          }
        }

        .req-score {

          // float:right;
          span {
            font-size: 36px;
            font-weight: 200;
            margin: auto;
          }
        }
      }

      .req-btn-container {
        display: flex;
        justify-content: center;
        padding-top: 16px;
      }
    }
  }
}
