/*
 * File: project-dropdown-options.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:29:21 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Sunday, 29th August 2021 2:41:25 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../variables';

.project-dropdown-options {
  max-width: 350px;
  position: relative;
  width: fit-content;

  .project-controls {
    display: inline-flex;
  }

  .add-project-item {
    margin: 4px auto;
  }

  .project-btns {
    @include border-radius(5px);
    @include box-shadow($shadow-box);
  }

  .project-dropdown {
    @include border-radius(5px);

    .dropdown-toggle {
      &::after {
        float: right;
        margin: 10px auto;
      }
    }

    .dropdown-menu {
      right: 0;
    }
  }

  .dropdown {
    @include box-shadow($shadow-box);
    @include border-radius(5px);

    min-width: 250px;

    button {
      min-width: 250px;
      text-align: left;

      &::after {
        text-align: right;
      }
    }
  }
}
