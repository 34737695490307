/*
 * File: project-controls.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 6:17:04 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.project-controls {
  margin-bottom: 16px;
}
