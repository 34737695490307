/*
 * File: welcome.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 4:22:42 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.welcome {
  align-items: center;
  background-color: $nuenergy-blue;
  bottom: 0;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: fixed !important;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 8000;

  &__container {
    color: #fff;
    display: flex;
    flex-direction: column;

    h1 {
      font-size: 4rem;
      font-weight: 600;
      max-width: 75vw;
      padding: 16px;
      text-align: center;
    }

    h3 {
      font-size: 1.5rem;
      max-width: 75vw;
    }

    span {
      // padding: 36px 0;
      margin: 36px auto;
      text-align: center;
    }

    img {
      @include border-radius(5px);
      @include box-shadow($shadow-box);

      background-color: #fff;
      height: 8rem;
      margin: 0 auto;
      padding: 16px;
    }
  }
}
