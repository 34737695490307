/*
 * File: aster-chart.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:38:10 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 7:03:26 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.aster-chart {

  // 'Modern' version
  .arc-background {
    transition-duration: 0.3s;
    z-index: 1;
  }

  .aster-chart-icon,
  .aster-score-2,
  .aster-score,
  .aster-score-percent {
    cursor: default;
  }

  .aster-score-2 {
    font-size: 28px;
    font-weight: 700;
  }

  // .aster-solid-arc-2 {
  //   transition-duration: 0.3s;
  // }

  // Regular version
  .aster-score {
    font-size: 56px;
    font-weight: bold;
  }

  .aster-solid-arc {
    transition-duration: 0.3s;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.aster-tooltip {
  background-color: black;
  border-radius: 6px;
  color: #fff;
  display: inline-flex;
  flex-direction: column;
  max-width: 1000px;
  padding: 5px;
  // text-align: center;
  white-space: nowrap;
  z-index: 1;

  &::after {
    border-color: transparent black transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';

    /* To the left of the tooltip */
    margin-top: -5px;
    position: absolute;
    right: 100%;
    top: 50%;
  }
}
