/*
 * File: benchmark.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 3rd September 2021 12:54:36 pm
 * Author: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Last Modified: Wednesday, 8th September 2021 12:38:51 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.header {
  align-items: left;
  display: flex;
  padding-top: 20px;
  width: 100%;
  font-size: 25px;
  font-weight: 200;
}

.organization-score {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
  width: 100%;
  font-size: 30px;
  font-weight: 700;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}
.organization-name {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 4px;
  width: 100%;
  font-size: 18px;
  font-weight: 300;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}

.no-benchmark-score {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;

  &__empty {
    padding: 16px;
    text-align: left;
  }
}

.benchmark-spinner {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 190px;
  top: 30px;
  color: #20a8d8;
}
