/*
 * File: project-name-header.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 4:24:43 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.project-name-header {
  margin: 0 1.5rem 0.75rem;
  position: relative;

  h2 {
    font-size: 2rem;
    font-weight: 350;
  }

  &::after {
    background: $nuenergy-blue;

    /* adjust this to move up and down. you may have to adjust the line height of the paragraph if you move it down a lot. */
    bottom: -4px;
    content: '';
    height: 2px;

    /******
   optional values below
   ******/

    /* center - (optional) use with adjusting width   */
    // margin: 0 auto;
    left: 0;
    position: absolute;

    /* optional animation */
    transition: 0.5s;
    transition: 0.5s;
    transition: 0.5s;
    transition: 0.5s;
    transition: 0.5s;
    // right: 0;
    width: 10%;
  }

  /* optional hover classes used with anmiation */
  &:hover::after {
    background: $nuenergy-purple;
    width: 20%;
  }
}
