/*
 * File: navigation.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:01:27 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Saturday, 31st July 2021 8:28:18 am
 * Modified By: Rafal Rybak (rafal.rybak@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../variables';

/* -- a. Navbar  --------------------------------------------- */

.navbar-nav {
  margin-left: auto !important;
}

.navbar,
.navbar:hover,
.account-dropdown,
.navbar-btn-container {
  height: 58px;
}

.navbar,
.navbar:hover {
  background-color: rgba(var(--primary-color-text), 1);
  color: rgba(var(--primary-color), 1);
}

.navbar-btn-container,
.account-dropdown {
  align-items: center;
  display: inline-flex;
}

.account-dropdown {
  background-color: rgba(var(--primary-color), 1);
  border: 0;
  border-radius: 0 !important;
  color: rgba(var(--primary-color-text), 1);
}

.account-dropdown,
.account-dropdown-name {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar a,
.navbar a:hover {
  color: rgba(var(--primary-color), 1);
  text-decoration: none;
}

.navbar .navbar-nav .nav-item {
  span,
  strong {
    line-height: 42px;
    margin-right: 16px;
    vertical-align: middle;
  }

  span {
    margin-right: 16px;
  }

  strong {
    margin-right: 8px;
  }
}

.nav-item.dropdown > a.dropdown-toggle::after {
  border: 0;
  height: 0;
  width: 0;
}

.navbar {
  padding: 0;

  &-brand {
    img {
      height: 58px;
      padding-right: 1rem;
    }
  }

  &-btn-container {
    padding: 0.875rem 1.5rem;

    a {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  .dropdown-menu {
    margin-right: 4px;
    min-width: 250px;
  }

  .dropdown-header {
    font-size: inherit;
  }

  .dropdown-item.user-role {
    label {
      padding-left: 0.5rem;
    }
  }

  .dropdown-item {
    color: rgb(0, 0, 0);
    line-height: 24px;

    &:hover {
      background-color: rgba(var(--secondary-color), 0.25);
      color: rgb(0, 0, 0);
    }

    > i {
      font-size: 24px;
      height: 24px;
      margin-right: 20px;
      text-align: center;
      vertical-align: middle;
      width: 24px;
    }

    > span {
      vertical-align: middle;
    }
  }

  .nav-burger {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .nav-burger[data-toggle='collapse'],
  .nav-burger[data-toggle='collapse']:hover {
    background-color: rgba(var(--primary-color-text), 1);
    border: 0;
    margin-right: 8px;
  }
}

.service-nav {
  background-color: transparent;
  display: flex;
  height: 80px;
  left: 0;
  margin-bottom: 16px;
  right: 0;
  top: 0;
  // padding-top: 10px;
  // padding-left: 120px;
  // margin-top: 58px;

  // background-color: #3cb64b;
}

.service-nav-btn {
  @include box-shadow($shadow-box);
  //@include box-shadow(0 0.5px 2.1px rgba(16, 27, 30, 0.2), 0 2.2px 4.9px rgba(16, 27, 30, 0.36), 0 5.7px 9.5px rgba(16, 27, 30, 0.5));
  @include border-radius(5px);

  align-items: center;
  background-color: rgba(var(--primary-color-text), 1);
  border: solid 1px $alto;
  color: rgba(var(--primary-color), 1);
  cursor: pointer;
  display: flex;
  height: 80px;
  margin-left: 20px;
  text-decoration: none;
  width: 225px;

  &.selected,
  &:hover {
    background-color: rgba(var(--secondary-color), 1);
    border: 0;
    color: rgba(var(--primary-color-text), 1);
    text-decoration: none;

    .icon-container {
      background-color: rgba(var(--primary-color-text), 1);
      color: rgba(var(--secondary-color), 1);
      text-decoration: none;
    }
  }

  &__title {
    font-family: 'Montserrat', Helvetica;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin: 0 auto;
    text-decoration: none;
    width: 140px;
  }

  .icon-container {
    @include box-shadow(-2px 0.6px 5px rgba(16, 27, 30, 0.092), -2px 7px 7px rgba(16, 27, 30, 0.35));
    @include border-radius(5px);
    @include border-radius(5px);

    background-color: rgba(var(--primary-color), 1);
    color: rgba(var(--primary-color-text), 1);
    display: flex;
    font-size: 30px;
    height: 48px;
    margin: 0 auto;
    padding: 9px;
    text-align: center;
    text-decoration: none;
    width: 48px;

    i {
      @include text-shadow(-2px 4px 4px rgba(16, 27, 30, 0.4));

      margin: 0 auto;
      text-decoration: none;
    }
  }
}

.service-help-btn {
  margin-left: auto;
}

/* -- a. Nav Drawer  ------------------------------------------ */
.nav-drawer {
  @include border-radius(5px);
  @include box-shadow($panel-box-shadow);

  background-color: rgba(var(--primary-color), 1);
  bottom: 15px;
  height: calc(100vh - (10px + 70px + 32px + 58px));
  margin-left: 15px;
  margin-top: ((10px + 70px + 16px + 58px));
  overflow-y: auto;
  padding: 16px 0;
  width: 140px;

  ul {
    @include border-radius(5px);

    list-style-type: none;
    padding-inline-start: 0;

    li {
      box-shadow: 0 4px 2px -2px rgba(var(--primary-color-light), 1);
      display: flex;
      line-height: 36px;
      // align-items: center;
      // justify-content: center;
      // text-align: center;
      padding: 10px 0;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

      a {
        align-items: center;
        display: inline-flex;
        flex-direction: column;
        margin: 0 auto;
        padding: 4px;
        width: 100%;

        span {
          color: rgba(var(--primary-color-text), 1);
          font-size: 14px;
          line-height: 14px;
          width: 100%;
        }

        i,
        h3 {
          color: #20a8d8;
          font-size: 20px;
          height: 36px;
          //width: 36px;
        }

        h3 {
          align-self: center;
          font-weight: bolder;
        }

        i,
        span {
          line-height: 14px;
          max-width: 150px;
          text-align: center;
          vertical-align: middle;
        }
      }

      &:hover,
      &:nth-child(odd):hover,
      &.selected {
        background-color: rgba(var(--secondary-color), 1);

        i,
        h3 {
          color: rgba(var(--primary-color-text), 1);
        }
      }

      &.disabled {
        color: rgba(0, 0, 0, 0.5);

        &:hover {
          background-color: inherit;
        }
      }
    }
  }

  .project-nav-item {
    background-color: rgba(var(--secondary-color), 0.15);
    // box-shadow: 0 4px 2px -2px rgba(var(--secondary-color-light), 1);
  }

  a,
  a:hover {
    color: inherit;
    display: flex;
    margin: 0;
    text-decoration: none;
  }

  & > ul > li > a > & > ul > ul {
    border-bottom: 0.5px solid rgba(var(--primary-color), 0.25);
    padding-inline-start: 0;
  }
}
