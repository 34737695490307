/*
 * File: variables.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 3:58:15 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:54:18 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */


/* - 1. Variables & Mixins ============================================= */

// NuEnergy Colors
$nuenergy-purple: #932a8e;
$nuenergy-orange: #f79432;
$nuenergy-blue: #1172ba;


// $color-map-blue: #20a8d8; // old
$color-map-blue: #0375bc;
$color-map-indigo: #6610f2;
$color-map-pink: #e83e8c;
$color-map-red: #f86c6b;
// $color-map-orange: #f8cb00; // old
$color-map-orange: #f79521;
// $color-map-green: #4dbd74; // old
$color-map-green: #3cb64b;
$color-map-yellow: #ffc107;
$color-map-purple: #6f42c1;
$color-map-violet: #932b8e;
$color-map-teal: #20c997;

$alto: rgba(219, 219, 219, 1);
$cape-cod: rgba(66, 66, 66, 1);

$aia-level-one: $color-map-green;
$aia-level-two: $color-map-blue;
$aia-level-three: $color-map-yellow;
$aia-level-four: $color-map-red;


// Shadowing 
$panel-box-shadow: 0 1px 1px rgba(16, 27, 30, 0.25),
  0 2px 2px rgba(16, 27, 30, 0.2),
  0 8px 8px rgba(16, 27, 30, 0.4),
  0 16px 16px rgba(16, 27, 30, 0.5);

$small-shadow: 0 1px 1px rgba(16, 27, 30, 0.25),
  0 2px 2px rgba(16, 27, 30, 0.2);

$shadow-box: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
  0 6.7px 5.3px rgba(0, 0, 0, 0.028),
  0 12.5px 10px rgba(0, 0, 0, 0.035),
  0 22.3px 17.9px rgba(0, 0, 0, 0.042);

@mixin box-shadow($params...) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

}

@mixin text-shadow($params...) {
  -moz-text-shadow: $params;
  -webkit-text-shadow: $params;
  text-shadow: $params;

}

@mixin border-radius($radius...) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;

}

@mixin transform($params...) {
  -webkit-transform: $params;
  -moz-transform: $params;
  -ms-transform: $params;
  -o-transform: $params;
  transform: $params;

}

@mixin linear-gradient-background-image($color1, $color2) {
  background-image: -webkit-gradient(linear, left top, left bottom, from($color1), to($color2));
  background-image: linear-gradient(to top, $color1, $color2);
  background-image: linear-gradient(to top, $color1, $color2);
  background-image: linear-gradient(to top, $color1, $color2);
  background-image: linear-gradient(to top, $color1, $color2);
  background-image: linear-gradient(to bottom, $color1, $color2);
}
