html {
    --primary-color: 66, 66, 66;
    --primary-color-light: 109, 109, 109;
    --primary-color-dark: 27, 27, 27;
    --primary-color-text: 255, 255, 255;
    --secondary-color: 32, 168, 216;
    --secondary-color-light: 155, 231, 255;
    --secondary-color-dark: 34, 134, 195;
    --secondary-color-text: 0, 0, 0;
}


body {
    margin: 0;
    /* font-family: Roboto !important; */
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
    /* background-color: rgb(255, 255, 255); */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.btn.btn-md,
.btn.btn-md:hover {
    color: rgb(var(--secondary-color));
    background-color: transparent;
    font-weight: bold;
    border: none;
    text-transform: uppercase;
}

.circle-icon,
.circle-icon-reverse,
.btn-floating-action {
    text-decoration: none;
    border-radius: 50%;
    height: 42px;
    width: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    vertical-align: middle;
    padding: 0;
}

.circle-icon,
.btn.circle-icon-reverse:hover {
    background-color: rgb(var(--secondary-color-dark));
    color: rgb(255, 255, 255);
}

.circle-icon-reverse,
.btn.circle-icon:hover {
    background-color: rgb(255, 255, 255);
    color: rgb(var(--secondary-color-dark));
}

.btn-floating-action,
.btn-floating-action:hover {
    background-color: rgb(var(--primary-color)) !important;
    color: rgb(var(--primary-color-text)) !important;
    margin-left: 4px;
    margin-right: 4px;
}


.btn.icon-btn {
    background-color: rgb(255, 255, 255);
    color: rgb(var(--secondary-color));
    margin-left: 4px;
    margin-right: 4px;
    padding: 0;
    height: 35px;
    width: 35px;
    line-height: 35px;
}

.btn.icon-btn-inverse-color {
    background-color: rgb(var(--secondary-color));
    color: rgb(255, 255, 255);
}


.floating-actions {
    list-style: none;
    z-index: 10;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 0.25em;
    position: absolute;
    width: 100%;
    height: 42px;
    bottom: -21px;
    left: 0;
}


.card {
    width: 100% !important;

}

/* .card-header {
    padding: 1.25rem;
} */

/* .card-header,
.card-footer {
    display: flex;
    background-color: rgb(var(--secondary-color));
    color: rgb(var(--secondary-text));
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
} */

.card-header>.circle-icon-reverse {
    margin-right: 16px;
}

/* .card-body ul {
    list-style: none;
    padding: 0;
}

.card-body li {
    display: flex;
    flex-direction: row;
    margin: 8px 0 8px 0;
    padding: 8px 8px 8px 8px;
    line-height: 36px;
    align-items: center;
    cursor: pointer;
} */

/* 
.card-body li.selected {
    border-radius: 4px;
    background-color: rgba(var(--secondary-color), 0.10);
}


.card-body li.selected:hover::after {
    color: rgba(var(--secondary-color), 0.25);
}

.card-body li:hover {
    border-radius: 4px;
    background-color: rgba(var(--secondary-color), 0.25);
}

.card-body a {
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    color: rgba(var(--secondary-color), 0.50);
    align-self: center;
    margin: 4px 0 4px auto;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    vertical-align: middle;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 12px;
    cursor: e-resize;
} */


.twoline-header h1,
.twoline-header h2,
.twoline-header h3,
.twoline-header h4,
.twoline-header h5 {
    margin-bottom: 0;
}

.twoline-header span {
    color: rgba(var(--secondary-color-text), 0.5);
    line-height: 12px;
    font-size: 12px;
}


.md-shadow-1dp {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.md-shadow-2dp {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14),
        0px 3px 1px -2px rgba(0, 0, 0, 0.12);
}

.md-shadow-3dp {
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2),
        0px 3px 4px 0px rgba(0, 0, 0, 0.14),
        0px 3px 3px -2px rgba(0, 0, 0, 0.12);
}

.md-shadow-4dp {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
        0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.md-shadow-5dp {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 5px 8px 0px rgba(0, 0, 0, 0.14),
        0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.md-shadow-6dp {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
        0px 6px 10px 0px rgba(0, 0, 0, 0.14),
        0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.md-shadow-7dp {
    box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2),
        0px 7px 10px 1px rgba(0, 0, 0, 0.14),
        0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.md-shadow-8dp {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.md-shadow-9dp {
    box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2),
        0px 9px 12px 1px rgba(0, 0, 0, 0.14),
        0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.md-shadow-10dp {
    box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2),
        0px 10px 14px 1px rgba(0, 0, 0, 0.14),
        0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.md-shadow-11dp {
    box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2),
        0px 11px 15px 1px rgba(0, 0, 0, 0.14),
        0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.md-shadow-12dp {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
        0px 12px 17px 2px rgba(0, 0, 0, 0.14),
        0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.md-shadow-13dp {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
        0px 13px 19px 2px rgba(0, 0, 0, 0.14),
        0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.md-shadow-14dp {
    box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2),
        0px 14px 21px 2px rgba(0, 0, 0, 0.14),
        0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.md-shadow-15dp {
    box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2),
        0px 15px 22px 2px rgba(0, 0, 0, 0.14),
        0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.md-shadow-16dp {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14),
        0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.md-shadow-17dp {
    box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2),
        0px 17px 26px 2px rgba(0, 0, 0, 0.14),
        0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.md-shadow-18dp {
    box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2),
        0px 18px 28px 2px rgba(0, 0, 0, 0.14),
        0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.md-shadow-19dp {
    box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2),
        0px 19px 29px 2px rgba(0, 0, 0, 0.14),
        0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.md-shadow-20dp {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
        0px 20px 31px 3px rgba(0, 0, 0, 0.14),
        0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.md-shadow-21dp {
    box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2),
        0px 21px 33px 3px rgba(0, 0, 0, 0.14),
        0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.md-shadow-22dp {
    box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2),
        0px 22px 35px 3px rgba(0, 0, 0, 0.14),
        0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.md-shadow-23dp {
    box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2),
        0px 23px 36px 3px rgba(0, 0, 0, 0.14),
        0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.md-shadow-24dp {
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
        0px 24px 38px 3px rgba(0, 0, 0, 0.14),
        0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}


/***************************************

            Auth

***************************************/

.auth-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: url(../assets/images/background.jpg);
    background-position: left center;
    background-repeat: repeat-x;
    background-color: #ffffff;
    height: 100%;
    width: 100%;
    animation: animatedBackground 20s linear infinite;
  }
  
  @keyframes animatedBackground {
    from { background-position: left center; }
    to   { background-position: right center; }
  }
  
  .auth-background:after {
    content: "";
    position: absolute;
    bottom: 10px;
    right: 10px;
    height: 80px;
    width: 250px;
    background-image: url(../assets/images/logo-nuenergy.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 250px auto;
    background-color: transparent;
  }



  .loading-background {
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    z-index: 700;
  
  }
  
  .spinner-animated  {
    position: absolute;
    left: 50%;
    margin: 0px auto;
    animation: rotate 1s infinite;
    height: 50px;
    width: 50px;
    z-index: 900;
  }
  
  .spinner-animated:before,
  .spinner-animated:after {
    border-radius: 50%;
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }
  
  .spinner-animated:before {
    animation: ball1 1s infinite;
    background-color: #7a507d;
    box-shadow: 30px 0 0 #e8954e;
    margin-bottom: 10px;
  }
  
  .spinner-animated:after {
    animation: ball2 1s infinite;
    background-color: #4677ae;
    box-shadow: 30px 0 0 #97bf0d;
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg) scale(0.8);
      -moz-transform: rotate(0deg) scale(0.8);
    }
    50% {
      -webkit-transform: rotate(360deg) scale(1.2);
      -moz-transform: rotate(360deg) scale(1.2);
    }
    100% {
      -webkit-transform: rotate(720deg) scale(0.8);
      -moz-transform: rotate(720deg) scale(0.8);
    }
  }
  
  @keyframes ball1 {
    0% {
      box-shadow: 30px 0 0 #f8b334 ;
    }
    50% {
      box-shadow: 0 0 0 #f8b334 ;
      margin-bottom: 0;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #f8b334 ;
      margin-bottom: 10px;
    }
  }
  
  @keyframes ball2 {
    0% {
      box-shadow: 30px 0 0 #97bf0d;
    }
    50% {
      box-shadow: 0 0 0 #97bf0d;
      margin-top: -20px;
      -webkit-transform: translate(15px,15px);
      -moz-transform: translate(15px, 15px);
    }
    100% {
      box-shadow: 30px 0 0 #97bf0d;
      margin-top: 0;
    }
  }
  
  .confirm-action-background {
    position: fixed !important;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--secondary-color-dark), 0.7);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    z-index: 8000;
  }
  
  
  .confirm-action-background .card {
    max-width: 560px;
  }
  
  .confirm-action-background .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
      
  }
  
  .confirm-action-background .card-body p {
    margin: 0 auto;
    text-align: center;
  }
  
  .confirm-action-background .card-footer {
      background-color: inherit;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
  }
  
  .confirm-action-background .btn-delete, .confirm-action-background .btn-delete:hover {
      color: red;
  }
  
  .confirm-action-background .confirm-action-principal {
      width: 100%;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1em;
  
  }
  

.Error {
	height: 100vh;
}

.Error .card {
	width: 560px;
	height: auto;
}



.idle-logout-background {
    align-items: center;
    justify-content: center;
    background-color: rgba(var(--secondary-color-dark), 0.7);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    display: flex;
    z-index: 800;
  }
  
  
  .idle-logout-background .card {
    max-width: 560px;
  }
  
  .idle-logout-background .card .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: left;
  }
  
  .idle-logout-background .card .card-body .btn {
      align-self: flex-end;
  }