/*
 * File: consent.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 4:46:46 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.consent {
  background-color: #fff;
  border-radius: 0.25em;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 10vh auto;
  max-width: 10in;
  padding: 1vw;

  &__header {
    margin-bottom: 5vh !important;
  }

  &__footer {
    margin-top: 5vh !important;
  }

  &__btns {
    display: flex;
    justify-content: space-evenly;
    max-width: 4in;
  }
}
