/*
 * File: tabs.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:32:34 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:40:18 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import '../variables';

.tabs {
  cursor: pointer;
  display: flex;
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  padding-right: 16px;
  width: 100%;
  width: fit-content;

  li {
    float: left;
    // justify-self: flex-start;
    margin: 0 0.5em 0 0;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  a {
    @include linear-gradient-background-image(#fff, #ddd);
    @include text-shadow(0 1px 0 rgba(255, 255, 255, 0.8));
    @include border-radius(5px 0 0 0);
    @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.4));

    color: #444;
    float: left;
    padding: 0.7em 0.75em;
    position: relative;
    text-decoration: none;

    &:hover,
    &:hover::after,
    &:focus,
    &:focus::after,
    .active {
      background: #fff;
      // @include linear-gradient-background-image(#fff, #ddd);
    }

    &:focus {
      outline: 0;
    }

    &::after {
      @include linear-gradient-background-image(#fff, #ddd);
      @include box-shadow(2px 2px 2px rgba(0, 0, 0, 0.4));
      @include transform(skew(10deg));
      @include border-radius(0 5px 0 0);

      bottom: 0;
      content: '';
      position: absolute;
      right: -0.5em;
      top: 0;
      width: 1em;
      z-index: 1;
    }
  }

  .active a,
  .active a::after {
    background: #fff;
    z-index: 3;
  }

}

.tabs-content-container {
  display: flex;
  flex-direction: column;
  min-width: 350px;

  .tabs-content {
    // height: fit-content !important;
    @include border-radius(0 5px 5px 5px);
    @include box-shadow($shadow-box);

    /* required */
    background-color: #fff;
    display: flex;
    flex-flow: row nowrap;
    min-height: 100px;
    position: relative;

    .content-display {
      box-sizing: border-box;

      /* required */
      flex: none;

      /* required */
      min-height: 100px;

      /* required */
      width: 100%;

    }
  }
}
