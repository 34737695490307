/*
 * File: coming-soon.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:44:00 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.coming-soon {
  h3 {
    font-size: x-large;
    font-weight: 250;
    padding: 4px;
    position: relative;
    top: 20px;
    width: 100%;
  }

  p {
    justify-content: center;
    padding: 20px;
    position: relative;
    text-align: center;
    top: 20px;
  }
}
