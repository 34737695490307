/*
 * File: general.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 3:57:46 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Sunday, 29th August 2021 2:28:43 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */

@import './variables';

/* ________________________________________________________________ */

/* I. General
___________________________________________________________________ */

body {
  --font-family-sans-serif: montserrat, 'Open Sans', helvetica, arial, sans-serif;
  // --font-family-sans-serif: Roboto -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  --font-family-monospace: mono-regular, menlo, monaco, consolas, 'Liberation Mono', 'Courier New', monospace;

  background-color: #ebedef;
  font-family: Montserrat, 'Open Sans', Helvetica, Arial, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

main {
  // position: relative;
  height: calc(100vh - 58px);
  margin-top: 58px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 10px 10px 2em !important;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-variant-ligatures: common-ligatures;
  font-variant-ligatures: common-ligatures;
  margin-bottom: 0 !important;
  text-rendering: optimizeLegibility;
}

a {
  transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

a:hover,
a:active {
  outline: 0;
}

input.form-control.invalid {
  border-color: #dc3545;
  color: #dc3545;
}

.dropdown-toggle.dropdown-toggle-split.btn {
  &.btn-secondary {
    border-left-color: #a9a9a9;
  }
}

.btn:disabled {
  cursor: not-allowed;
}

.btn-primary {
  background-color: rgba(var(--secondary-color), 1) !important;
  border-color: rgba(var(--secondary-color), 1) !important;
  color: rgba(var(--primary-color-text), 1) !important;
}

.progress {
  border-radius: 50px;
}

.shadow-br {
  @include box-shadow($shadow-box);
  @include border-radius(5px);
}

/* ------------------------- Colors ------------------------- */

.color-map-blue-text {
  color: $color-map-blue;
}

.color-map-indigo-text {
  color: $color-map-indigo;
}

.color-map-pink-text {
  color: $color-map-pink;
}

.color-map-red-text {
  color: $color-map-red;
}

.color-map-orange-text {
  color: $color-map-orange;
}

.color-map-green-text {
  color: $color-map-green;
}

.color-map-yellow-text {
  color: $color-map-yellow;
}

.color-map-purple-text {
  color: $color-map-purple;
}

.color-map-violet-text {
  color: $color-map-violet;
}

.color-map-teal-text {
  color: $color-map-teal;
}
