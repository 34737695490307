/*
 * File: dashboard.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:19:25 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:41:07 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.dashboard {
  display: grid;
  // padding: 36px;
  gap: 36px;
  grid-auto-flow: column dense;
  grid-template-columns: fit-content(40%) fit-content(40%) 1fr;
  max-width: 100vw;

  .aia-chart {
    align-self: center;
    // display: flexbox;
    margin: 0 auto;
  }

  &::after {
    clear: both;
    content: '';
    display: table;
  }
}
