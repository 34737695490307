/*
 * File: scorecard-self-assessment.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:40:21 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 20th August 2021 2:13:03 pm
 * Modified By: Archit Bhatia (archit.bhatia@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.scorecard-self-assessment {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  padding-top: 16px;
  width: 100%;

  &__empty {
    padding: 16px;
    text-align: left;
  }

  .score-header {
    float: right;
    font-size: x-large;
    font-weight: 250;
    padding: 4px;
    width: 100%;
  }

  // &__chart {}

  &__legend {
    width: 100%;

    ul {
      list-style: none;
      padding: 0 10px;
      // background-color: #3cb64b;

      li {
        align-items: center;
        cursor: default;
        display: flex;
        justify-content: space-between;
        padding: 4px;

        .r-label {
          align-items: center;
          display: flex;
          justify-content: space-between;
          padding: 4px;
          position: relative;
          vertical-align: middle;
          width: 100%;

          &:hover {
            border-bottom: solid 1px #c5c5c5;
          }

          .r-header {
            hyphens: auto;
            margin: auto 0;
            max-width: 70%;
            // vertical-align: middle;
            overflow-wrap: break-word;
            word-wrap: break-word;
          }

          span {
            // float: right;
            font-weight: 900;
          }
        }

        &::before {
          @include border-radius(3px);
          @include box-shadow($small-shadow);

          background-color: red;
          content: '';
          display: inline-block;
          height: 12px;
          line-height: normal;
          margin: 10px 6px 10px 0;
          vertical-align: middle;
          width: 12px;
        }

        &.color-map-blue::before {
          background-color: $color-map-blue;
        }

        &.color-map-indigo::before {
          background-color: $color-map-indigo;
        }

        &.color-map-pink::before {
          background-color: $color-map-pink;
        }

        &.color-map-red::before {
          background-color: $color-map-red;
        }

        &.color-map-orange::before {
          background-color: $color-map-orange;
        }

        &.color-map-green::before {
          background-color: $color-map-green;
        }

        &.color-map-yellow::before {
          background-color: $color-map-yellow;
        }

        &.color-map-purple::before {
          background-color: $color-map-purple;
        }

        &.color-map-teal::before {
          background-color: $color-map-teal;
        }
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    margin: 2vh auto;
  }
}
