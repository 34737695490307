/*
 * File: aia.scss
 * Project: Machine Trust Platform
 * File Created: Friday, 9th July 2021 5:39:11 pm
 * Author: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Last Modified: Friday, 9th July 2021 6:40:48 pm
 * Modified By: Tarek Sanger (tarek@nuenergy.ai)
 * -----
 * Copyright 2017 - 2021 NuEnergy.ai, NuEnergy.ai
 */
@import '../variables';

.aia-chart {
  @include box-shadow($shadow-box);
  @include border-radius(5px);

  background-color: #fff;
  border: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  height: 205px;
  padding: 1rem;
  position: relative;
  width: max-content;

  .canadian-flag {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  &__no-data {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    max-width: 300px;
    padding: 1.5rem;
    text-align: center;
  }

  &__gov-logo {
    display: contents;
    height: 100%;
    width: 100%;

    img {
      margin-bottom: 1rem;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .legend {
    color: rgba(var(--primary-color), 1);
    display: flex;
    flex-direction: column;

    img {
      max-height: 66px;
      max-width: 155px;
      // min-height: inherit;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    padding-right: 0;

    li {
      font-size: 13px;
      margin-top: 10px;
      padding: 0;
      white-space: nowrap;

      &::before {
        @include box-shadow($small-shadow);
        @include border-radius(2px);

        background-color: red;
        content: '';
        display: inline-block;
        height: 12px;
        margin-right: 8px;
        width: 12px;
      }

      &.aia-legend-impact-score::before {
        background-color: $color-map-pink;
      }

      &.aia-legend-mitigation-score::before {
        background-color: $color-map-blue;
      }

      &.aia-legend-total-score::before {
        background-color: $color-map-green;
      }
    }
  }

  .level-container {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    padding: 0 16px;

    .level-wrapper {
      text-align: center;

      // justify-content:;
      .aia-level {
        @include box-shadow($shadow-box);
        @include border-radius(50%);

        font-size: 50px;
        height: 125px;
        line-height: 125px;
        margin: 0 auto;
        text-align: center;
        width: 125px;

        &.aia-level-one {
          background-color: $aia-level-one;
        }

        &.aia-level-two {
          background-color: $aia-level-two;
        }

        &.aia-level-three {
          background-color: $aia-level-three;
        }

        &.aia-level-four {
          background-color: $aia-level-four;
        }

        span {
          color: rgba(var(--primary-color-text), 1);
        }
      }

      .level-label {
        font-size: 24px;
        font-weight: 500;

        &.aia-level-one {
          color: $aia-level-one;
        }

        &.aia-level-two {
          color: $aia-level-two;
        }

        &.aia-level-three {
          color: $aia-level-three;
        }

        &.aia-level-four {
          color: $aia-level-four;
        }
      }

      span {
        cursor: default;
      }
    }
  }

  .aia-bar-chart-container {
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    overflow: visible;
    padding: 16px 5px;

    svg {
      overflow: visible;
    }

    // background-color: transparent;
    // @include box-shadow($shadow-box);
    .aia-bar-chart-bar {
      overflow: visible;
      // background-color: #20a8d8;
    }
  }
}

.algorithmic-impact-assessment-container {
  @include box-shadow($shadow-box);
  @include border-radius(5px);

  background-color: #fff;
  display: flex;
  margin: 0 auto;
  max-width: 10in;

  iframe {
    @include border-radius(5px);

    border: 0 !important;
    min-height: 100vh;
    width: 100%;
  }
}
